import React, { Component, ChangeEvent } from 'react';
import classes from './EditEntryForm.module.scss';
import Modal from '../UI/Modal/Modal';
import NumberInput from '../UI/NumberInput/NumberInput';
import Button from '../UI/Button/Button';
import { IProduct } from '../../shared/interfaces';
import { DataManager } from '../../shared/DataManager';
import Checkbox from "../Checkbox/Checkbox";

export interface IProps {
    inputEntry: IProduct;
    saveEditedEntry: (entry: IProduct) => void;
    cancelEdit: () => void;
    deleteEntry: (id: number) => void;
    autoCheck: boolean;
}
export interface IState {
    entry: IProduct;
    oldEntry: IProduct;
    vgName: string;
}

class EditEntryForm extends Component<IProps, IState> {
    state: IState = {
        entry: {
            title: this.props.inputEntry.title,
            vgNum: this.props.inputEntry.vgNum,
            amount: this.props.inputEntry.amount || 1,
            unit: this.props.inputEntry.unit || 'Stück',
            hasVgNum: this.props.inputEntry.hasVgNum,
            id: this.props.inputEntry.id,
            checked: this.props.inputEntry.checked,
            country: this.props.inputEntry.country || undefined,
            date: this.props.inputEntry.date || Date.now(),
            order: this.props.inputEntry.order
        },
        oldEntry: {
            ...this.props.inputEntry
        },
        vgName: ''
    };

    componentDidMount() {
        if (!this.props.inputEntry.vgNum) {
            return true;
        }
        const dbEntry = DataManager.getProductFromDb(this.props.inputEntry.vgNum);
        // @ts-ignore
        if (!dbEntry || dbEntry.title !== this.props.inputEntry.title) {
            this.setState({ vgName: dbEntry ? dbEntry.title : '' });
        }
    }

    changeInputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let inputValue = event.currentTarget.value;
        let inputID = event.currentTarget.id;
        this.setState(previousState => {
            const updatedEntry = {
                ...previousState.entry
            };
            // @ts-ignore
            updatedEntry[inputID] =
                inputID === 'vgNum' && !isNaN(Number(inputValue)) ? Number(inputValue) : inputValue;
            return { entry: updatedEntry };
        });
    };
    changeCheckboxHandler = () => {
        this.setState(previousState => {
            const updatedEntry = { ...previousState.entry };
            updatedEntry.hasVgNum = !previousState.entry.hasVgNum;
            return { entry: updatedEntry };
        });
    };

    increaseValueHandler = () => {
        this.setState(previousState => {
            const updatedEntry = { ...previousState.entry };
            if (updatedEntry.amount < 1) {
                updatedEntry.amount += 0.25
            } else {
                updatedEntry.amount += 1;
            }
            return { entry: updatedEntry };
        });
    };

    decreaseValueHandler = () => {
        this.setState(previousState => {
            const updatedEntry = { ...previousState.entry };
            if (updatedEntry.amount > 1) {
                updatedEntry.amount -= 1;
            } else if (updatedEntry.amount > 0) {
                updatedEntry.amount -= 0.25;
            } else if (updatedEntry.amount <= 0) {
                updatedEntry.amount = 0
            }
            return { entry: updatedEntry };
        });
    };

    changeNumberInputHandler = (inputValue: number) => {
        this.setState(previousState => {
            const updatedEntry = {
                ...previousState.entry
            };
            updatedEntry.amount = inputValue;
            return { entry: updatedEntry };
        });
    };

    cancelEdit = () => {
        this.props.cancelEdit();
    };

    deleteEntry = () => {
        this.props.deleteEntry(this.state.entry.id);
    };

    saveEntry = () => {
        let updatedEntryWithVgNum = { ...this.state.entry };
        // Wenn vgNum vorhanden oder geändert, rufe entsprechenden Eintrag aus der DB ab und ergänze Eintrag entsprechend
        if (this.state.entry.vgNum && this.state.entry.vgNum !== this.props.inputEntry.vgNum) {
            let product = DataManager.getProductByVgNum(this.state.entry.vgNum);
            if (product.id !== -1) {
                updatedEntryWithVgNum.title = `${product.title} (${
                    this.state.entry.title ? this.state.entry.title : ''
                })`;
                updatedEntryWithVgNum.vgNum = product.vgNum;
                updatedEntryWithVgNum.country = DataManager.getCountryCodeById(
                    product.countryID
                );
                updatedEntryWithVgNum.hasVgNum = true;
                updatedEntryWithVgNum.checked = this.props.autoCheck;
            }
        }

        if (this.state.entry.vgNum) {
            let entry = DataManager.getProductByVgNum(this.state.entry.vgNum);
            if (entry.title !== this.state.entry.title) {
                entry.title = this.state.entry.title;
                entry.vgNum = this.state.entry.vgNum;
                DataManager.saveUserProduct(entry);
            }
        }

        this.props.saveEditedEntry(updatedEntryWithVgNum);
    };

    render() {
        return (
            <Modal modalClosed={this.cancelEdit} show={true}>
                <div className={classes.editEntryForm}>
                    <h3>Eintrag bearbeiten</h3>
                    <div className={classes.row}>
                        <label>
                            <span className={classes.label}>VG-Nummer</span>
                            <input
                                type="number"
                                id="vgNum"
                                value={this.state.entry.vgNum}
                                onChange={this.changeInputHandler}
                            />
                        </label>
                    </div>
                    <div className={classes.row}>
                        <label>
                            <span className={classes.label}>Bezeichnung</span>
                            <div style={{ flex: '1 1 auto', width: '80%' }}>
                                <input
                                    type="text"
                                    id="title"
                                    value={this.state.entry.title}
                                    onChange={this.changeInputHandler}
                                />
                                {this.state.vgName ? (
                                    <div className={classes.originalTitle}>
                                        Originalbezeichnung: {this.state.vgName}
                                    </div>
                                ) : null}
                            </div>
                        </label>
                    </div>
                    <div className={classes.row}>
                        <label>
                            <span className={classes.label}>Dieser Artikel hat eine VG-Nummer</span>
                            <Checkbox inline active={this.state.entry.hasVgNum} onClick={this.changeCheckboxHandler}/>
                        </label>
                    </div>
                    <div className={[classes.row, classes.twoColumnRow].join(' ')}>
                        <label>
                            <span className={classes.label}>Menge</span>
                            <NumberInput
                                id="amount"
                                value={this.state.entry.amount}
                                decrease={this.decreaseValueHandler}
                                increase={this.increaseValueHandler}
                                changed={this.changeNumberInputHandler}
                            />
                        </label>
                        <label>
                            <span className={classes.label}>Einheit</span>
                            <select
                                id="unit"
                                value={this.state.entry.unit}
                                onChange={this.changeInputHandler}
                            >
                                <option>Stück</option>
                                <option>g</option>
                                <option>kg</option>
                                <option>Liter</option>
                                <option>Box(en)</option>
                                <option>Packung(en)</option>
                                <option>Tüte(n)</option>
                                <option>Beutel</option>
                                <option>Schale(n)</option>
                                <option>Topf</option>
                                <option>Netz(e)</option>
                                <option>Flasch(en)</option>
                                <option>Dose(n)</option>
                                <option>Bund</option>
                            </select>
                        </label>
                    </div>
                    <div className={classes.buttons}>
                        <Button clicked={this.deleteEntry} buttonType="Secondary">
                            Löschen
                        </Button>
                        <Button clicked={this.cancelEdit} buttonType="Secondary">
                            Abbrechen
                        </Button>
                        <Button clicked={this.saveEntry} buttonType="Primary">
                            Speichern
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default EditEntryForm;
